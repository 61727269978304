$montserrat:'Montserrat', sans-serif;
$black:#000000;
$white:#ffffff;
$yellow:#ffd12a;
$open_sans:'Open Sans', sans-serif;

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

body{
	font-family: $open_sans;
	ul{
		list-style: none;
		padding: 0;
		margin: 0;
	}
	h1,h2,h3,h4,h5,h6,p,a,ul,ol{
		margin: 0;
		font-weight: 400;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}

div.vertical_height{
	@include fill;
	> div{
		display: table;
		width: 100%;
		height: 100%;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

a.all_click{
	@include fill;
}

a.more1{
	//padding: 27px 55px 31px 55px;
	padding: 27px 30px 27px 30px;
	border-radius: 50px;
	border: 1px solid $black;
	background-color: $black;
	font-size: 18px;
	text-transform: uppercase;
	color: $white;
	display: inline-block;
	font-weight: 700;
}

a.more2{
	padding: 27px 55px 31px 55px;
	border-radius: 50px;
	border: 1px solid $white;
	background-color: $white;
	font-size: 18px;
	text-transform: uppercase;
	color: $black;
	display: inline-block;
	font-weight: 700;
}

a.more3{
	padding: 24px 76px 24px 76px;
	border-radius: 50px;
	border: 1px solid $yellow;
	background-color: $yellow;
	font-size: 18px;
	text-transform: uppercase;
	color: $black;
	display: inline-block;
	font-weight: 700;
}

div.default_font{
	font-size: 15px;
	line-height: 18px;
	h1{
		font-size: 60px;
		line-height: 70p;
	}
	h2{
		font-size: 40px;
		line-height: 74px;
	}
	h3{
		font-size: 30px;
		line-height: 36px;
	}
	h4{
		font-size: 24px;
		line-height: 30px;
	}
	h5{
		font-size: 20px;
		line-height: 36px;
	}
	h6{
		font-size: 18px;
		line-height: 24px;
	}
	p{
		
	}
	ul{
		padding-left: 20px;
		li{
			list-style: disc;
			a{
				display: block;
			}
		}
	}
	ol{
		padding-left: 20px;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
	strong{
		font-weight: 700;
	}
}

div.text_desc{
	div.default_font{
		h1,h2,h3,h4,h5,h6,p,ul,ol,a{
			//padding-bottom: 44px;
			padding-bottom: 17px;
			&:last-child{
				padding-bottom: 0;
			}
		}
		table{
			width: 100%;
		 	tr{
				&:first-child{
					color: $white;
					background-color: $yellow;
				}
				td{
					padding: 15px;
					vertical-align: top;
				}
			}
		}
	}
}

div.page{
	overflow: hidden;
	position: relative;
}

header.header{
	div.container-fluid{
		> div.row{
			> div{
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	//padding: 2% 0;
	padding: 1.5% 0;
	div.logo_box{
		position: relative;
		img{
			width: 100%;
			height: auto;
		}
	}
	div.header_nav{
		margin-left: 3.5%;
		padding-top: 4%;
		//padding-bottom: 16px;
		//margin-left: 91px;
		padding-bottom: 1.5%;
		position: relative;
		/*&:after{
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			width: 200%;
			border-bottom: 1px solid #e5bc29;
		}*/
	}
	div.menu_box{
		//padding-top: 10px;
		> nav.menu{
			position: relative;
			z-index: 11;
			> ul{
				> li{
					display: inline-block;
					vertical-align: top;
					//margin-right: 3%;
					margin-right: 15px;
					> a{
						text-decoration: none;
						color: $black;
						font-size: 21px;
						//font-size: 1.1vw;
						position: relative;
						transition: 0.3s all ease;
						@media only screen and (max-width: 1550px) {
							font-size: 18px;
						}
						@media only screen and (max-width: 1370px) {
							font-size: 15px;
						}
						@media only screen and (max-width: 1200px) {
							font-size: 11px;
						}
						&:hover{
							//color: $yellow;
							&:before{
								left: -13px;
								opacity: 1;
							}
							&:after{
								right: -13px;
								opacity: 1;
							}
						}
						&:before{
							opacity: 0;
							transition: 0.3s all ease;
							position: absolute;
							left: -20px;
							top: 0;
							content: '';
							display: block;
							height: 100%;
							width: 10px;
							background-position: left center;
							background-repeat: no-repeat;
							background-size: contain;
							background-image: url('./../img/bracket_left.png');
						}
						&:after{
							opacity: 0;
							transition: 0.3s all ease;
							position: absolute;
							right: -20px;
							top: 0;
							content: '';
							display: block;
							height: 100%;
							width: 10px;
							background-position: right center;
							background-repeat: no-repeat;
							background-size: contain;
							background-image: url('./../img/bracket_right.png');
						}
						&.log_in{
							cursor: pointer;
						}
					}
				}
			}
		}
	}
	div.social{
		position: relative;
		z-index: 11;
		> a, .langs{
			display: inline-block !important;
			vertical-align: middle;
			margin-right: 10px;
		}
		.langs{
			margin-right: 0;
			> ul{
			  >	li{
				display: inline-block;
				vertical-align: top;
				margin-right: 10px;
				&:last-child{
					margin-right: 0;
				}
				img{
					height: 22px;
					width: auto;
				}
				}
			}
		}
		/*img{
			width: 65%; height: auto;
		}*/
	}
	div.top_menu{
		> div{
			display: inline-block;
			vertical-align: middle;
		}
	}
}

section.top2{
	div.container-fluid{
		> div.row{
			> div{
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	div.top_content{
		margin-left: auto;
		margin-right: auto;
		width: 60%;
		div.default_font{
			padding-bottom: 10%;
			h1{
				padding-bottom: 10%;
			}
		}
		div.button_holder{
			> a{
				display: block;
				width: 100%;
				text-align: center;
				width: 80%;
				margin-bottom: 5%;
				
			}
			&:last-child{
				> a{
					margin-bottom: 0;
				}
			}
		}
	}
	div.top_desc2{
		position: relative;
		background-color: $yellow;
		div.default_font{
			font-size: 30px;
			line-height: 40px;
			h1{
				//font-size: 3.1vw;
				font-size: 59px;
				line-height: 68px;
				//line-height: 3.5vw;
			}
			/*h3{
				font-size: 1.55vw;
			}*/
		}
	}
	div.image2{
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		div.big_logo{
			position: absolute;
			top: -22%;
			right: 0;
			//width: 79%;
			width: 46%;
			img{
				max-width: 100%;
				height: auto;
			}
		}
	}
}

.text-justify{
	text-align: justify;
}

header.section_header{
	div.default_font{
		font-size: 24px;
    	line-height: 30px;
		position: relative;
		&:before{
			content: '';
			display: block;
			right: 110%;
			top: 50%;
			height: 3px;
			background-position: right top;
			background-repeat: repeat-x;
			background-image: url('./../img/section_header_border.png');
			position: absolute;
			width: 100%;
		}
		h2{
			line-height: 52px;
			font-weight: 700;
			color: $black;
			strong{
				//color: $yellow;
			}
		}
	}
}

section.development{
	.langs{
		margin-bottom: 30px;
		ul{
			text-align: center;
			li{
				position: relative;
				display: inline-block;
				vertical-align: top;
				margin-right: 15px;
				transition: 0.3s all ease;
				&:last-child{
					margin-right: 0;
				}
				&:hover{
					margin-top: -10px;
					margin-bottom: 10px;
				}
			}
		}
	}
	//padding: 5% 0;
	//padding: 3% 0;
	padding: 0 0 3% 0;
	header.section_header{
		padding-bottom: 10%;
	}
	div.text_desc{
		padding-bottom: 7%;
		div.default_font{
			h5{
				font-weight: 300;
			}
		}
	}
	div.counts{
		padding-bottom: 10%;
		width: 100%;
		position: relative;
		&:after{
			content: '';
			display: block;
			width: 100%;
			top: 0;
			left: 106%;
			position: absolute;
			background-repeat: no-repeat;
			background-position: left top;
			background-image: url('./../img/lines1.png');
			height: 40px;
		}
		> ul{
			> li{
				display: inline-block;
				vertical-align: top;
				width: 19%;
				text-align: center;
				padding-top: 140px;
				background-repeat: no-repeat;
				background-position: center top;
				background-size: 100% auto;
				font-size: 26px;
				font-weight: 300;
				strong{
					font-size: 36px;
					font-weight: 700;
					line-height: 56px;
				}
				&:nth-child(1){
					background-image: url('/img/1.svg');
				}
				&:nth-child(2){
					background-image: url('/img/2.svg');
				}
				&:nth-child(3){
					background-image: url('/img/3.svg');
				}
				&:nth-child(4){
					background-image: url('/img/4.svg');
				}
				&:nth-child(5){
					background-image: url('/img/5.svg');
				}
			}
		}
	}
	div.lines2{
		position: relative;
		div.h5{
			padding-left: 240px;
		}
		&:after{
			content: '';
			display: block;
			width: 150%;
			top: 0;
			right: 20%;
			position: absolute;
			background-repeat: no-repeat;
			background-position: left top;
			background-image: url('./../img/lines2.png');
			height: 160px;
		}
	}
	div.shape{
		position: absolute;
		top: 30%;
		right: 0;
	}
}

section.newsletter{
	position: relative;
	z-index: 3;
	//top: 54px;
	/*div.shape{
		position: absolute;
		bottom: 100%;
		left: 0;
	}*/
	> div{
		display: inline-block;
		vertical-align: middle;
		position: relative;
	}
	div.newsletter_left{
		width: 45%;
		position: absolute;
		top: -10%;
		height: 120%;
		left: 0;
		div.image{
			top: 0;
			left: 0;
			height:100%;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
	}
	div.newsletter_right{
		background-color: $yellow;
		padding: 100px 0 0 0;
		width: 55%;
		height: 497px;
		margin-left: 45%;
		> div.desc{
			padding-left: 5%;
			padding-right: 33%;
		}
		header.section_header{
			margin-bottom: 50px;
			div.default_font{
				    font-size: 20px;
    				line-height: 36px;
				h1,h2,h3,h4,h5,h6,p{
					font-weight: 300;
					margin-bottom: 3%;
				}
				strong{
					color: $black;
				}
				&:before{
					display: none;
				}
			}
		}
		a.more1{
			font-size: 15px;
			position: relative;
			&:after{
				content: '';
				display: block;
				width: 195%;
				height: 3px;
				background-repeat: no-repeat;
				background-position: left top;
				background-image: url('./../img/newsletter_border.png');
				position: absolute;
				top: 50%;
				left: 120%;
			}
		}
	}
}

section.training{
	position: relative;
	padding-top: 8%;
	background-color: $black;
	div.container{
		position: relative;
		z-index: 2;
	}
	header.section_header{
		padding-bottom: 30px;
		div.default_font{
			h2{
				color: $white;
			}
			strong{
				color: $yellow;
			}
		}
	}
	div.text_desc{
		padding-bottom: 30px;
		div.default_font{
			color: $white;
			h1,h2,h3,h4,h5,p{
				font-weight: 300;
				    font-size: 20px;
					line-height: 36px;
			}
		}
	}
	div.space{
		height: 0;
		padding-bottom: 43.5%;
		width: 100%;
		position: relative;
		z-index: 2;
		div.clouds1{
			width: 100%;
			height: 0;
			padding-bottom: 19%;
			background-position: right bottom;
			background-repeat: no-repeat;
			background-image: url('./../img/clouds1.png');
			position: absolute;
			left: 0;
			bottom: 0;
			background-size: contain;
		}
		div.clouds2{
			width: 100%;
			background-position: center bottom;
			background-repeat: no-repeat;
			background-image: url('./../img/clouds2.png');
			height: 0;
			padding-bottom: 25%;
			position: absolute;
			left: 0;
			bottom: -60px;
			background-size: contain;
		}
	}
	div.stars{
		@include fill;
		background-position: left top;
		background-repeat: no-repeat;
		background-image: url('./../img/stars_cover.png');
		background-attachment: fixed;
	}
	div.rocket{
		padding-top: 3%;
		display: inline-block;
		position: absolute;
		left: 52.6%;
		div.rocket_desc{
			position: absolute;
				top: 50%;
				right: 160%;
				
				width: 300%;
			div.default_font{
				color: $yellow;
				font-size: 30px;
    			line-height: 36px;
			}
		}
	}
	
.fly {
	animation: fly 2s infinite alternate;
}

	@keyframes fly{
	  0% {transform: rotate(10deg); transform-origin: left;}
	  25% {transform: rotate(5deg); transform-origin: right;}
	  50% {transform: rotate(0); transform-origin: top;}
	  75% {transform: rotate(-5deg);}
	  100% {transform: rotate(-10deg); transform-origin: bottom;}
	}
}

.fly2 {
    position:relative;
    -webkit-animation: ease fly2 1.5s infinite; /* Safari 4+ */
    -moz-animation:    ease fly2 1.5s infinite; /* Fx 5+ */
    -o-animation:      ease fly2 1.5s infinite; /* Opera 12+ */
    animation:         ease fly2 1.5s infinite;
}

@-webkit-keyframes fly2 {
    0%   { top: 0; }
    50% {top:20px;}
    100% { top: 0; }
}
@-moz-keyframes fly2 {
    0%   { top: 0; }
    50% {top:20px;}
    100% { top: 0; }
}
@-o-keyframes fly2 {
    0%   { top: 0; }
    50% {top:20px;}
    100% { top: 0; }
}
@keyframes fly2 {
    0%   { top: 0; }
    50% {top:20px;}
    100% { top: 0; }
}

section.download{
	position: relative;
	z-index: 5;
	padding-bottom: 5%;
	header.section_header{
		padding-bottom: 8%;
	}
	div.download_links{
		> ul{
			li{
				width: 49%;
				margin-right: 2%;
				display: inline-block;
				vertical-align: top;
				margin-bottom: 2%;
				div.default_font{
					padding-left: 30px;
					background-repeat: no-repeat;
					//background-position: left center;
					background-position: left top 10px;
					background-image: url('./../img/yellow_mark.png');
					color: $black;
					a{
						font-size: 20px;
						line-height: 36px;
						color: $black;
						transition: 0.3s all ease;
						display: block;
						&:hover{
							color: $yellow;
						}
					}
				}
				&:nth-child(2n){
					margin-right: 0;
				}
			}
		}
	}
}

section.contact{
	position: relative;
	z-index: 6;
	div.container{
		> div{
			position: relative;
			&:after{
				content: '';
				display: block;
				width: 100%;
				background-position: center;
				background-repeat: repeat-x;
				display: block;
				height: 3px;
				background-image: url('./../img/lines3.png');
			}
		}
	}
	header.section_header{
		padding-bottom: 5%;
	}
	div.text_desc{
		div.default_font{
			font-size: 20px;
			line-height: 36px;
			p{
				padding-bottom: 10px;
				font-size: 20px;
				line-height: 36px;
				//font-weight: 700;
			}
		}
	}
	
	div.teachers{
		padding-bottom: 5%;
	}
}

section.partners{
	padding: 3% 0;
	img{
		max-width: 100%;
		height: auto;
	}
	
	div.partenrs_top{
		padding-bottom: 8%;
	}
}

footer.footer{
	padding:60px 0;
	border-top: 1px solid $yellow;
	width: 1200px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	z-index: 3;
	div.default_font{
		font-size: 18px;
    	line-height: 24px;
	}
	div.footer_top{
		ul{
			li{
				display: inline-block;
				vertical-align: top;
				&:first-child{
					margin-right: 30px;
				}
			}
		}
	}
	div.footer_bottom{
		div.to_up{
			p{
				cursor: pointer;
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 83px;
				background-repeat: no-repeat;
				background-position: left center;
				background-image: url('./../img/arr_top.png');
				display: inline-block;
				font-size: 18px;
    			line-height: 24px;
			}
		}
	}
	div.devs{
		ul{
			li{
				display: inline-block;
				vertical-align: middle;
				div.default_font{
					color: #767676;
				}
			}
		}
	}
}

@media only screen and (min-width: 1501px) and (max-width: 1800px) {
	a.more1{font-size: 16px;}
	a.more2{font-size: 16px;}
}

@media only screen and (min-width: 1501px) and (max-width: 1770px) {
	div.popup_content{padding: 30px 30px!important; margin: 0 50px;}
	header.popup_header div.default_font{padding-bottom: 15px!important; margin-bottom: 15px!important;}
	header.popup_header div.default_font h2{font-size: 22px!important; line-height: 28px!important;}
	div.holder p{font-size: 20px!important; padding-bottom: 12px!important;}
	div.holder{margin-bottom: 12px!important;}
	div.holder input.input{height: 57px!important; font-size: 20px!important;}
	.sbSelector:link, .sbSelector:visited, .sbSelector:hover{font-size: 20px!important; padding:22px 0 18px 47px!important;}
	#myform_orange_places .sbSelector:link, #myform_orange_places .sbSelector:visited, #myform_orange_places .sbSelector:hover{font-size: 10px!important; padding:18px 0 17px 15px!important;}
	.sbHolder{height: 57px!important;}
	#myform_orange_places .sbHolder{height: 50px!important;}
	div.holder:last-child{padding-top: 20px!important;}
	div.holder input.submit{height: 56px!important;}
	div.holder a.cancel{padding: 15px 0!important;}
	.sbToggle{background-size: 28px!important;}
	#myform_orange_places .sbToggle{background-size: 10px!important;}
	.sbOptions a{padding: 15px 47px 13px 47px!important;}
	.sbOptions{font-size: 17px!important;}
	div.thanks h2{font-size: 40px!important; line-height: 45px!important; padding-bottom: 7%!important;}
	
	.chosen-container{height: 55px!important;}
	.chosen-single > span{font-size: 20px!important;}
	div.page .chosen-single > span{padding: 10px 0 100% 37px!important;}
	div.page .chosen-container-single .chosen-single div b{background-size: auto 17px!important}
	.chosen-container-single .chosen-search{padding:15px 0!important;}
	.chosen-container .chosen-results li{font-size: 20px!important;}
	
	section.movies > div.movie_left div.desc{padding-left: 25%!important;}
	section.newsletter div.newsletter_right > div.desc{padding-right: 25%!important;}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	a.more1, a.more2{font-size: 12px!important;}
	//section.top2 div.top_content div.button_holder > a{width: auto;}
	 img.fly{width: 60%; height: auto;}
	section.training div.space{padding-bottom: 43.5%;}
	section.training div.rocket div.rocket_desc{right: 125%;}
	
	 div.popup_content{padding: 30px 30px!important; margin: 0 0px;}
	 header.popup_header div.default_font{padding-bottom: 15px!important; margin-bottom: 15px!important;}
	 header.popup_header div.default_font h2{font-size: 22px!important; line-height: 28px!important;}
	 div.holder p{font-size: 15px!important; padding-bottom: 12px!important;}
	 div.holder{margin-bottom: 12px!important;}
	 div.holder input.input{height: 57px!important; font-size: 20px!important;}
	#myform_orange_places div.holder input.input{height: 50px!important; font-size: 20px!important;}
	 .sbSelector:link, .sbSelector:visited, .sbSelector:hover{font-size: 20px!important; padding:22px 0 18px 47px!important;}
	#myform_orange_places .sbSelector:link, #myform_orange_places .sbSelector:visited, #myform_orange_places .sbSelector:hover{font-size: 10px!important; padding:18px 0 17px 15px!important;}
	 .sbHolder{height: 57px!important;}
	#myform_orange_places .sbHolder{height: 50px!important;}
	 div.holder:last-child{padding-top: 20px!important;}
	 div.holder input.submit{height: 56px!important;}
	 div.holder a.cancel{padding: 15px 0!important;}
	 .sbToggle{background-size: 28px!important;}
	#myform_orange_places .sbToggle{background-size: 10px!important;}
	 .sbOptions a{padding: 15px 47px 13px 47px!important;}
	 .sbOptions{font-size: 17px!important;}
	div.thanks h2{font-size: 40px!important; line-height: 45px!important; padding-bottom: 7%!important;}
	
	/*header.section_header div.default_font h2{font-size: 40px!important; line-height: 48px!important;}
	section.newsletter div.newsletter_left{width: 52%!important;}
	section.newsletter div.newsletter_right{width: 48%!important;}
	section.newsletter{top: 56px!important;}
	section.newsletter div.newsletter_left div.image{padding-bottom: 64.5%!important;}*/
	
	//header.header div.menu_box{padding-top: 2%!important;}
	
	a.more1{padding: 5% 7% 5% 7%;}
	a.more2{padding: 5% 7% 5% 7%;}
	section.top2 div.top_content div.default_font h1, section.top2 div.top_content div.default_font h2, section.top2 div.top_content div.default_font h3, section.top2 div.top_content div.default_font h4, section.top2 div.top_content div.default_font h5, section.top2 div.top_content div.default_font h6, section.top2 div.top_content div.default_font p{padding-bottom: 5%;}
	
	section.movies > div.movie_left div.desc{padding-left: 25%!important;}
	section.newsletter div.newsletter_right > div.desc{padding-right: 25%!important;}
	
	.chosen-container{height: 55px!important;}
	.chosen-single > span{font-size: 20px!important;}
	div.page .chosen-single > span{padding: 10px 0 100% 37px!important;}
	div.page .chosen-container-single .chosen-single div b{background-size: auto 17px!important}
	.chosen-container-single .chosen-search{padding:15px 0!important;}
	.chosen-container .chosen-results li{font-size: 20px!important;}
	
	section.subpage_top div.top_image div.light{right: 1%!important;}

	
	section.top2 div.top_desc2 div.default_font h1{font-size: 39px; line-height: 48px;}
	section.top2 div.top_desc2 div.default_font{font-size: 20px; line-height: 30px;}
	
	div.popup_content header.popup_header{padding-bottom: 15px!important; margin-bottom: 15px!important;}
	div.popup_content header.popup_header .h2{font-size: 30px; line-height: 40px;}
	div.popup_content header.popup_header .h3{font-size: 20px; line-height: 26px;}
	div.holder .h5{font-size: 15px; line-height: 20px;}
	div.holder .h6{font-size: 13px; line-height: 19px;}
	
	section.movies > div.movie_left div.desc{padding-left: 18%!important;}
	section.newsletter div.newsletter_right > div.desc{padding-right: 18%!important;}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	a.more1, a.more2{font-size: 12px!important;}
	//section.top2 div.top_content div.button_holder > a{width: auto;}
	
	 img.fly{width: 50%; height: auto;}
	section.training div.space{padding-bottom: 43.5%;}
	section.training div.rocket div.rocket_desc{right: 125%;}
	
	div.popup_content{padding: 30px 30px!important; margin: 0 50px}
	header.popup_header div.default_font{padding-bottom: 15px!important; margin-bottom: 15px!important;}
	header.popup_header div.default_font h2{font-size: 22px!important; line-height: 28px!important;}
	div.holder p{font-size: 20px!important; padding-bottom: 12px!important;}
	div.holder{margin-bottom: 12px!important;}
	div.holder input.input{height: 57px!important; font-size: 20px!important;}
	.sbSelector:link, .sbSelector:visited, .sbSelector:hover{font-size: 20px!important; padding:22px 0 18px 47px!important;}
	#myform_orange_places .sbSelector:link, #myform_orange_places .sbSelector:visited, #myform_orange_places .sbSelector:hover{font-size: 10px!important; padding:18px 0 17px 15px!important;}
	.sbHolder{height: 57px!important;}
	#myform_orange_places .sbHolder{height: 50px!important;}
	div.holder:last-child{padding-top: 20px!important;}
	div.holder input.submit{height: 56px!important;}
	div.holder a.cancel{padding: 15px 0!important;}
	.sbToggle{background-size: 28px!important;}
	#myform_orange_places .sbToggle{background-size: 10px!important;}
	.sbOptions a{padding: 15px 47px 13px 47px!important;}
	.sbOptions{font-size: 17px!important;}
	div.thanks h2{font-size: 40px!important; line-height: 45px!important; padding-bottom: 7%!important;}
	
	/*header.section_header div.default_font h2{font-size: 35px!important; line-height: 43px!important;}
	section.newsletter div.newsletter_left{width: 52%!important;}
	section.newsletter div.newsletter_right{width: 48%!important; padding: 3% 0!important;}
	section.newsletter div.newsletter_right div.default_font h5{font-size: 15px!important; line-height: 31px!important;}
	section.newsletter div.newsletter_left div.image{padding-bottom: 60%!important;}*/
	
	footer.footer{width: 90%!important; padding:  30px 0!important;}
	
	//header.header div.header_nav{margin-left: 0!important;}
	//header.header div.menu_box{padding-top: 1%;}
	
	a.more1{padding: 5% 7% 5% 7%;}
	a.more2{padding: 5% 7% 5% 7%;}
	section.top2 div.top_content div.default_font h1, section.top2 div.top_content div.default_font h2, section.top2 div.top_content div.default_font h3, section.top2 div.top_content div.default_font h4, section.top2 div.top_content div.default_font h5, section.top2 div.top_content div.default_font h6, section.top2 div.top_content div.default_font p{padding-bottom: 5%;}
	
	section.movies > div.movie_left div.desc{padding-left: 15%!important;}
	section.newsletter div.newsletter_right > div.desc{padding-right: 15%!important;}
	
	section.subpage_top div.top_image div.light{height: 450px!important;}
	
	.chosen-container{height: 55px!important;}
	.chosen-single > span{font-size: 20px!important;}
	div.page .chosen-single > span{padding: 10px 0 100% 37px!important;}
	div.page .chosen-container-single .chosen-single div b{background-size: auto 17px!important}
	.chosen-container-single .chosen-search{padding:15px 0!important;}
	.chosen-container .chosen-results li{font-size: 20px!important;}
	
	div.popup1,div.popup2,div.popup8,div.popup3,div.popup4,div.popup5{-ms-overflow-y:scroll;}
	div.popup1,div.popup2,div.popup8,div.popup3,div.popup4,div.popup5{overflow: scroll;}
	
	section.subpage_top div.top_image div.light{right: 1%!important; top:-35%!important;}
	
	
	
	section.top2 div.top_desc2 div.default_font h1{font-size: 39px; line-height: 48px;}
	section.top2 div.top_desc2 div.default_font{font-size: 20px; line-height: 30px;}
	
	section.movies > div.movie_left div.desc{padding-left: 10%!important; padding-right: 5%!important;}
	section.newsletter div.newsletter_right > div.desc{padding-right: 10%!important;}
	section.movies > div.movie_left div.desc a.more1#start_audio_desc{margin-left: 0!important; margin-top: 15px;}
	section.movies > div.movie_left div.desc a.more1{width: 100%;}
}

@media only screen and (min-width: 992px) and (max-width: 1540px) {
	header.section_header{
		div.default_font{font-size: 13px; line-height: 23px;}
	div.default_font h1{font-size: 36px; line-height: 56px;}
	div.default_font h2{font-size: 30px; line-height: 50px;}
	div.default_font h3{font-size: 25px; line-height: 45px;}
	div.default_font h4{font-size: 20px; line-height: 24px;}
	div.default_font h5{font-size: 18px; line-height: 38px;}
	div.default_font h6{font-size: 15px; line-height: 35px;}
	}
	
	section.short_boxes header.section_header{padding-bottom: 4%!important;}
	section.short_boxes div.short_box div.default_font h3{font-size: 22px; line-height: 43px;}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	section.map div.map_selects form > ul > li{width: 100%!important; margin-right: 0!important; margin-bottom: 15px;}
	section.map div.map_selects form > ul > li div.holder2{width: 100%!important;}
	section.map div.map_selects form > ul > li .sbHolder{width: 100%!important;}
}

@media only screen and (min-width: 640px) and (max-width: 991px) {
	div.popup1 div.popup_content{margin: 0 17%!important;}
}

@media only screen and (min-width: 320px) and (max-width: 639px) {
	div.popup_content{margin: 15px!important;}
	.close1.icon{top: 5px!important; right: 5px!important;}
	
	section.map div.map_holder > div.map_info .close1.icon{top: 20px!important; right: 20px!important;}
	
	section.subpage_top div.top_image div.light{width: 100%!important; right: 0!important;}
	
	section.knowledge div.scenario_list > ul > li div.files > ul > li{width: 100%!important; margin-right: 0!important;}
	
	div.check_box > div.desc p{padding-left: 35px;}
	
	section.movies > div.movie_left div.desc a.more1{font-size: 12px!important; width: 100%;}
	section.movies > div.movie_left div.desc a.more1{margin-left: 0!important; margin-top: 15px;}
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
	section.educators div.holder input.input{height: 57px!important; font-size: 11px!important; padding-left: 10px!important;}
	section.educators div.holder p{font-size: 17px !important;}
	//div.check_box > div{vertical-align: top!important;}
	/*div.holder .sbSelector:link, .sbSelector:visited, .sbSelector:hover{font-size: 17px!important; padding: 30px 0 30px 10px!important}*/
	div.holder .sbToggle{background-size: 17px!important;}
	.sbOptions a{font-size: 11px!important; padding: 15px 0 15px 10px!important;}
	
	header.header div.logo_box img{width: 120px; margin-left: 15px;}
	header.header div.menu_box > nav.menu > ul {text-align: center;}
	header.header div.menu_box > nav.menu > ul > li > a{font-size: 22px;}
	header.header div.menu_box > nav.menu > ul > li{margin-right: 0; margin-bottom: 15px; width: 100%;}
	header.header div.social{text-align: right; padding-right: 15px;}
	header.header div.social img{width: auto;}
	header.header div.header_nav:after{display: none;}
	
	nav.menu{display: none; padding-top: 20px;}
	header.header div.header_nav{margin-left: 0; padding-top: 0px; padding-bottom: 0px;}
	
	div.rwd_button{width: 30px; position: absolute; top: 10px; right: 15px;}
	div.rwd_button > div {width: 100%; height: 3px; margin-bottom: 3px; background-color: $yellow;}
	div.rwd_button > div:last-child{margin-bottom:0;}
	
	.auto_height{height: auto!important; padding: 30px 15px;}
	section.top2 div.image2{height: 300px!important;}
	section.top2 div.vertical_height{position: relative;}
	section.top2 div.vertical_height > div{display: block!important;}
	section.top2 div.vertical_height > div > div {display: block!important;}
	section.top2 div.top_content{width: 100%; text-align: center;}
	section.top2 div.top_content div.button_holder {padding: 0 15px;}
	section.top2 div.top_content div.button_holder > a{width: 100%;}
	section.top2 div.top_content div.default_font h1, section.top2 div.top_content div.default_font h2, section.top2 div.top_content div.default_font h3, section.top2 div.top_content div.default_font h4, section.top2 div.top_content div.default_font h5, section.top2 div.top_content div.default_font h6, section.top2 div.top_content div.default_font p{padding-bottom: 15px;}
	a.more1, a.more2{font-size: 13px;}
	
	section.top2 div.top_desc2 div.default_font h1 {font-size: 36px; line-height: 56px;}
	section.top2 div.top_desc2 div.default_font h3 {font-size: 25px; line-height: 45px;}
	
	div.default_font{font-size: 13px; line-height: 23px;}
	div.default_font h1{font-size: 36px; line-height: 56px;}
	div.default_font h2{font-size: 30px; line-height: 50px;}
	div.default_font h3{font-size: 25px; line-height: 45px;}
	div.default_font h4{font-size: 20px; line-height: 40px;}
	div.default_font h5{font-size: 18px; line-height: 38px;}
	div.default_font h6{font-size: 15px; line-height: 35px;}
	
	section.development div.counts > ul > li{width: 100%;}
	
	section.development div.lines2 div.default_font{padding-left: 0;}
	section.development div.lines2:after{display: none;}
	
	/*section.newsletter{top: 0!important;}
	section.newsletter div.newsletter_right > div.desc{padding-left: 15px; padding-right: 15px;}
	section.newsletter div.newsletter_left{width: 100%;}
	section.newsletter div.newsletter_left div.image{height: 300px; width: 100%;}
	section.newsletter div.newsletter_right{width: 100%;}
	section.newsletter div.newsletter_right a.more1:after{display: none;}
	section.newsletter div.newsletter_right > div.desc{text-align: center;}*/
	
	section.contact div.default_font{padding-bottom: 10px;}
	
	header.section_header div.default_font{text-align: center;}
	header.section_header div.default_font:before{display: none;}
	
	section.training div.space{display: none;}
	section.development div.counts:after{display: none;}
	
	section.download div.download_links > ul li{width: 100%; margin-right: 0; margin-bottom: 15px;}
	footer.footer{width: 100%; padding: 30px 0;}
	footer.footer > div{padding-left: 15px; padding-right: 15px;}
	footer.footer div.footer_top ul li:first-child{margin-right: 0;}
	div.footer_top{text-align: center;}
	
	div.big_logo{display: none;}
	
	footer.footer div.devs ul li{width: 100%;}
	div.text-right.devs{text-align: center; margin: 15px 0 10px 0;}
	
	 div.popup1,div.popup2,div.popup8,div.popup3,div.popup4,div.popup5{overflow: scroll;}
	 div.popup_content{padding: 15px 15px!important;}
	 header.popup_header div.default_font{padding-bottom: 15px!important; margin-bottom: 15px!important;}
	 header.popup_header div.default_font h2{font-size: 18px!important; line-height: 24px!important;}
	 div.holder p{font-size: 17px!important; padding-bottom: 12px!important;}
	 div.holder{margin-bottom: 12px!important;}
	 div.holder input.input{height: 57px!important; font-size: 11px!important; padding-left: 10px!important;}
	.sbSelector:link, .sbSelector:visited, .sbSelector:hover{font-size: 11px!important; padding:22px 0 18px 10px!important;}
	.sbHolder{height: 57px!important;}
	 div.holder:last-child{padding-top: 10px!important;}
	 input.submit{height: 56px!important; font-size: 15px!important;}
	 a.cancel{padding: 15px 0!important; margin-bottom: 15px; font-size: 15px!important;}
	.sbToggle{background-size: 18px!important;}
	.sbOptions a{padding: 15px 10px 13px 10px!important;}
	.sbOptions{font-size: 11px!important;}
	
	div.thanks h2{font-size: 22px!important; line-height: 27px!important; padding-bottom: 30px!important;}
	a.more3{font-size: 15px!important; padding: 24px!important;}
	
	div.shape{display: none!important;}
	
	section.training{margin-bottom: 30px;}
	section.contact div.text_desc{padding-left: 0!important;}
	section.contact div.text_desc div.default_font{padding-left: 0!important;}
	section.partners div.partenrs_top{padding-bottom: 0!important;}
	section.partners img{margin: 15px 0;}
	
	section.development div.counts > ul > li{background-size: 140px!important;}
	
	section.news div.news_articles > div.col-md-4{width: 100%;}
	section.news div.image{background-position: center top!important; height: 350px!important;}
	
	section.movies > div.movie_right div.image{position: relative!important; background-position: center top!important;}
	section.movies > div.movie_left{width: 100%!important; padding: 30px 0!important; height: auto!important;}
	section.movies > div.movie_right{position: relative!important; top:auto!important; height: 350px!important; width: 100%!important;}
	section.movies > div.movie_left div.desc{padding-left: 15px!important; padding-right: 15px!important; text-align: center;}
	
	section.newsletter{display: flex; flex-flow: column;}
	section.newsletter div.newsletter_right{order: 1; align-items: stretch;}
	section.newsletter div.newsletter_left{order: 2; align-items: stretch;}
	section.newsletter div.newsletter_right{width: 100%!important; margin-left: 0!important;  padding: 30px 0!important; height: auto!important;}
	section.newsletter div.newsletter_right > div.desc{padding-left: 15px!important; padding-right: 15px!important; text-align: center;}
	section.newsletter div.newsletter_left{position: relative!important; top:auto!important; height: 350px!important; width: 100%!important;}
	section.newsletter div.newsletter_left div.image{background-position: center top!important;}
	
	section.news div.more_news{text-align: center; padding-top: 0!important; padding-bottom: 30px;}
	
	section.map div.text_desc div.default_font{min-height: auto!important; margin-bottom: 30px;}
	section.map div.image{text-align: center;}
	section.map div.image img{position: relative!important; top:auto!important; left: auto!important; max-width: 100%;}
	
	section.subpage_top div.page_title{min-height: 100px!important; text-align: center; position: relative;z-index: 3;}
	section.about_movie div.container.container2{height: 100%!important;}
	
	section.map header.section_header div.default_font{background-color: transparent!important; display: block!important;}
	
	section.map div.map_holder > div.map_info{width: 100%!important; height: 100%!important; opacity: 0.9;}
	
	section.map div.map_holder > div.map_info .close1.icon{top: 20px!important; right: 20px!important;}
	
	section.news_details div.map_holder{margin-top: 30px; height: 512px!important; padding-bottom: 0!important;}
	
	section.knowledge div.scenario_list > ul > li div.files > ul{text-align: center!important;}
	section.knowledge div.scenario_list > ul > li:first-child{padding-top: 0;}
	section.knowledge header.section_header div.default_font{display: block!important; padding-right: 0!important;}
	div.scenario_list{text-align: center!important;}
	
	section.subpage_top div.top_image div.light{height: 430px!important;}
	
	section.partners div.col-md-4{text-align: center!important;}
	
	div.holder div.check_box > div.desc p{font-size: 15px!important; line-height: 30px!important;}
	
	div.popup3 div.sign_in{padding: 0!important;}
	
	.chosen-container{height: 55px!important;}
	.chosen-single > span{font-size: 11px!important;}
	div.page .chosen-single > span{padding: 10px 0 100% 0!important;}
	div.page .chosen-container-single .chosen-single div b{background-size: auto 10px!important}
	.chosen-container-single .chosen-search{padding:15px 0 10px 0!important;}
	.chosen-container .chosen-results li{font-size: 11px!important; padding: 11px 17px!important;}
	
	div.page .ajax_search_input{margin-left: 3%!important;}
	
	div.popup1,div.popup2,div.popup8,div.popup3,div.popup4,div.popup5{-ms-overflow-y:scroll;}
	
	div.underslider_logotypes div.col-md-3{text-align: center!important; margin-bottom: 30px;}
	div.underslider_logotypes{padding-bottom: 0!important;}
	div.undertop_logotypes div.col-md-9{padding: 0 15px!important;}
	
	section.news header.section_header div.default_font:before{display: none!important;}
	
	header.header div.top_menu > div{width: 100%;}
	header.header div.social{text-align: right;}
	
	section.subpage_top div.page_title div.default_font{width: 100%!important; margin: 0!important; padding: 0 15px;}
	section.subpage_top div.page_title div.default_font h1{font-size: 30px!important;}
	
	div.check_box > div.desc p, div.check_box > div.desc label{font-size: 15px!important;}
	div.check_box > div.check{width: 45px!important;}
	div.check_box > div.desc{width: 80%!important;}
	section.educators div.holder label{font-size: 18px!important; padding-bottom:0!important; margin-bottom: 15px!important}
	
	div.popup1 div.holder p, div.popup1 div.holder label, div.popup4 div.holder p, div.popup4 div.holder label, div.popup5 div.holder p, div.popup5 div.holder label, div.popup6 div.holder p, div.popup6 div.holder label, div.popup7 div.holder p, div.popup7 div.holder label{font-size: 18px!important;}
	
	section.download div.download_links > ul li div.default_font a{font-size: 13px;}
	section.contact div.text_desc div.default_font {font-size: 13px;}
	section.contact div.text_desc div.default_font p{font-size: 13px;}
	section.contact header.section_header{padding-bottom: 0;}
	header.section_header div.default_font{padding-bottom: 0;}
	
	.only_text div.text_desc div.default_font{font-size: 13px!important; line-height: 30px!important;}
	section.short_boxes div.short_box header div.link div.default_font a {font-size: 13px!important; line-height: 30px!important;}
	section.short_boxes div.short_box div.desc div.default_font {font-size: 13px!important; line-height: 30px!important;}
}

div.popup1, div.popup4, div.popup5, div.popup6, div.popup7 {
	display: none;
	z-index: 15;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255,255,255,0.8);
	div.popup_content{
		background-color: $white;
		padding: 40px 40px;
		border: 1px solid rgba(32,40,50,0.5);
		position: relative;
	}
	header.popup_header{
		div.default_font{
			padding-bottom: 30px;
			margin-bottom: 30px;
			position: relative;
			&:after{
				content: '';
				display: block;
				height: 3px;
				width: 100%;
				position: absolute;
				top: 100%;
				left: 0;
				background-image: url('./../img/lines3.png');
				background-position: left top;
			}
			h2{
				line-height: 50px;
			}
		}
	}
	div.holder{
		margin-bottom: 24px;
		&:last-child{
			padding-top: 30px;
			margin-bottom: 0;
		}
		p, label{
			font-size: 26px;
			font-weight: 300;
			color: #000000;
			padding-bottom: 24px;
		}
		label{
			display: block;
		}
		input.input{
			width: 100%;
			height: 77px;
			border: 1px solid #acacac;
			//border-radius: 50px;
			border-radius: 15px;
			padding-left: 47px;
			outline: none;
			font-family: $montserrat;
			color: #000000;
			font-size: 26px;
			font-weight: 300;
			outline: none;
		}
		input.submit{
			width: 100%;
			height: 66px;
			background-color: $yellow;
			font-family: $montserrat;
			font-size: 20px;
			font-weight: 700;
			color: $black;
			border-radius: 50px;
			border: 1px solid $yellow;
			outline: none;
		}
		a.cancel{
			text-align: center;
			display: block;
			width: 100%;
			padding: 20px 0;
			background-color: $black;
			font-family: $montserrat;
			font-size: 20px;
			font-weight: 700;
			color: $white;
			border-radius: 50px;
			border: 1px solid $black;
		}
	}
}

div.popup6, div.popup7{
	padding-left: 50px;
	overflow-y: scroll;
	header{
		margin-bottom: 20px;
		img{
			max-width: 190px;
			height: auto;
		}
	}
	div.text_desc{
		div.default_font{
			text-align: justify;
		}
	}
}

@CHARSET "UTF-8";
.sbHolder{
	position: relative;
	
	padding: 0;
	height:77px;
	//border-radius: 50px;
	border-radius: 15px;
	margin:0;	
	width:100%;
	background-color:#fff;
	border: 1px solid #acacac;
	
}
.sbSelector{
	display: block;
	left: 0;
	line-height: 15px;
	outline: none;
	overflow: hidden;
	position: absolute;
	top: 0;
}
.sbSelector:link, .sbSelector:visited, .sbSelector:hover{
	padding: 30px 0 30px 47px ;
	width:80%;
	outline: none;
	text-decoration: none;
	color:#000000;
	font-family:$montserrat;
	font-weight:300;
	font-size:26px;
	
}
.sbToggle{
	display: block;
	outline: none;
	position: absolute;
	right: 0;
	top: 0;
	width: 20%;
	height:100%;
	background: url('../img/select_arrow.png') center no-repeat;
	
	
	
	
}

.sbHolderDisabled{
	background-color: #3C3C3C;
	border: solid 1px #515151;
}
.sbHolderDisabled .sbHolder{
	
}
.sbHolderDisabled .sbToggle{
	
}
.sbOptions{
	background-color: #f4f4f4;
	border: solid 1px #acacac;
	list-style: none;
	left: -1px;
	padding: 0;
	position: absolute;
	top: 100% !important;
	width: 275px;
	z-index: 1;
	overflow-y: auto;
	font-size:20px;
	font-weight: 300;
	//border-radius: 50px;
	border-radius: 15px;
	color:#666;
	background-color:#fff;
}
.sbOptions li{
	padding: 0px;
	left:0;
}
ul.sbOptions {
	left:-1px !important;
	max-height:192px !important;
	width:100%;
}
.sbOptions a{
	display: block;
	outline: none;
	padding: 20px 47px 19px 47px;
	color:#000 !important;
	font-family:$montserrat;
	font-weight: 300;

	&.disable {
		background-color:#ffd12a;
	}
}
.sbOptions a:link, .sbOptions a:visited{
	text-decoration: none;
	color:#333 !important;
}
.sbOptions a:hover{
	background-color:#eee;
}
.sbOptions li.last a{
	border-bottom: none;

}
.sbOptions .sbDisabled{
	border-bottom: dotted 1px #515151;
	display: block;
	padding: 7px 0 7px 3px;
}
.sbOptions .sbGroup{
	border-bottom: dotted 1px #515151;
	display: block;
	font-weight: bold;
	padding: 7px 0 7px 3px;
}
.sbOptions .sbSub{
	padding-left: 17px;
}

div.thanks{
	padding: 20% 15%!important;
	text-align: center;
	h2{
		padding-bottom: 15%;
		line-height: 55px;
		font-size: 50px;
		font-weight: 300;
	}
}

div.footer_top{
	margin-bottom: 15px;
}

.close1.icon {
  color:  $black;;
  position: absolute;
  margin-top: 0;
  margin-left: 0;
  width: 21px;
  height: 21px;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 15;
}
.close1.icon:before {
  content: '';
  position: absolute;
  top: 10px;
  width: 21px;
  height: 2px;
  background-color: $black;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.close1.icon:after {
  content: '';
  position: absolute;
  top: 10px;
  width: 21px;
  height: 2px;
  background-color:  $black;;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

section.technical_brake{
	height: 100vh;
	width: 100%;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 100;
	background-color: rgba(255,255,255,0.8);
	h2{padding-bottom: 0!important;}
	div.popup_content{
		background-color: $white;
		padding: 60px 50px;
		border: 1px solid rgba(32,40,50,0.5);
		position: relative;
	}
}
.multi_list{
	margin: 10px;
	li{margin: 2px;font-size: 13px;display: inline-block; vertical-align: top;}
}

section.news{
	//padding:5% 0 0 0;
	header.section_header{
		margin-bottom: 50px;
		div.default_font{
			h2{
				background-color: $white;
				position: relative;
				z-index: 5;
				display: inline-block;
				padding-right: 15px;
				strong{
					color: $black;
				}
			}
			&:before{
				content: '';
				display: block;
				//left: 100%;
				left: 0;
				top: 90%;
				height: 3px;
				background-position: right top;
				background-repeat: repeat-x;
				background-image: url(./../img/section_header_border.png);
				position: absolute;
				width: 400%;
			}
		}
	}
	div.more_news{
		padding-top: 3%;
		a{
			display: inline-block;
			padding: 15px 30px;
			font-size: 15px;
			font-weight: 700;
			background-color: $yellow;
			border-radius: 50px;
			color: $black;
			text-decoration: none;
		}
	}
	div.image{
		margin-bottom: 14px;
		height: 270px;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 10px;
		border: 1px solid $yellow;
	}
	div.desc{
		padding: 0 14px;
		div.date{
			font-size: 10px;
			color: $black;
			margin-bottom: 14px;
			p{
				font-weight: 700;
			}
		}
		div.text_desc{
			div.default_font{
				h3{
					font-size: 18px;
					line-height: 24px;
				}
			}
		}
	}
	article{
		position: relative;
		margin-bottom: 40px;
		> a{
			color: #000000;
			display: block;
		}
		&:hover{
			
			.bracket_more{
				//color: #ffd12a;
				&:before{
					left: -5px;
				}
				&:after{
					right: -5px;
				}
			}
		}
	}
	div.news_articles{
		> div.col-md-4{
			float: none;
			display: inline-block;
			vertical-align: top;
		}
	}
	div.sites{
		margin:0 0 50px 0;
		ul{
			text-align: center;
			li{
				display: inline-block;
				vertical-align: top;
				margin-right: 35px;
				&:last-child{
					margin-right: 0;
				}
				a{
					font-size: 20px;
					color: #969696;
					padding: 0 14px;
					position: relative;
					font-weight: 500;
					&:hover{
						&:before{
							opacity: 1;
							left: -5px;
						}
						&:after{
							opacity: 1;
							right: -5px;
						}
					}
					&:before{
						opacity: 0;
						transition: 0.3s all ease;
						position: absolute;
						left: 0;
						top: 0;
						content: '';
						display: block;
						height: 100%;
						width: 10px;
						background-position: left top;
						background-repeat: no-repeat;
						background-image: url('./../img/bracket_left.png');
					}
					&:after{
						opacity: 0;
						transition: 0.3s all ease;
						position: absolute;
						right: 0;
						top: 0;
						content: '';
						display: block;
						height: 100%;
						width: 10px;
						background-position: right top;
						background-repeat: no-repeat;
						background-image: url('./../img/bracket_right.png');
					}
				}
			}
		}
	}
}

.bracket_more{
	display: inline-block;
	font-size: 12px;
	color: #666666;
	padding: 3px 19px 5px 19px;
	position: relative;
	margin-top: 17px;
	transition: 0.3s all ease;
	&:hover{
		//color: #ffd12a;
		&:before{
			left: -5px;
		}
		&:after{
			right: -5px;
		}
	}
	&:before{
		transition: 0.3s all ease;
		position: absolute;
		left: 0;
		top: 0;
		content: '';
		display: block;
		height: 100%;
		width: 10px;
		background-position: left top;
		background-repeat: no-repeat;
		background-image: url('./../img/bracket_left.png');
	}
	&:after{
		transition: 0.3s all ease;
		position: absolute;
		right: 0;
		top: 0;
		content: '';
		display: block;
		height: 100%;
		width: 10px;
		background-position: right top;
		background-repeat: no-repeat;
		background-image: url('./../img/bracket_right.png');
	}
}

section.movies{
	position: relative;
	> div{
		display: inline-block;
		vertical-align: middle;
		position: relative;
		&.movie_left{
			background-color: $black;
			padding: 50px 0 0 0;
			width: 55%;
			height: 497px;
			div.desc{
				padding-left: 33%;
				//padding-right: 5%;
				padding-right: 15px;
				header.section_header{
					margin-bottom: 50px;
					div.default_font{
						color: $white;
						h2{
							font-weight: 300;
							color: $white;
							margin-bottom: 3%;
						}
						strong{
							color: $white;
						}
						&:before{
							display: none;
						}
					}
				}
				ul{
					position: relative;
					padding-right: 5%;
					@media only screen and (max-width: 1199px) {
						padding-right: 0;
					}
					&:before{
						content: '';
						display: block;
						width: 195%;
						height: 3px;
						background-repeat: repeat-x;
						background-position: right top;
						background-image: url(/img/section_header_border.png);
						position: absolute;
						top: 50%;
						right: 110%;
					}
					li{
						display: inline-block;
						vertical-align: top;
						width: 49%;
						text-align: center;
						@media only screen and (max-width: 768px) {
							width: 100%;
						}
						&:nth-child(odd){
							margin-right: 2%;
							@media only screen and (max-width: 768px) {
								margin-right: 0;
							}
						}
						a.more1{
							display: block;
							width: 100%;
							padding-left: 0;
							padding-right: 0;
							background-color: $yellow;
							color: $black;
							font-size: 12px;
							position: relative;
							margin: 5px 0;
							@media only screen and (max-width: 1199px) {
								font-size: 10px !important;
								margin-top: 5px !important;
							}
							&#start_audio_desc{
								&:before{
									display: none;
								}
							}
						}
					}
				}
			}
		}
		&.movie_right{
			width: 45%;
			position: absolute;
			top: -10%;
			height: 120%;
			right: 0;
			div.image{
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				z-index: 10;
			}
		}
	}
}

section.movies, section.newsletter{
	a.more1{
		padding: 23px 52px 23px 52px;
		cursor: pointer;
	}
}

div.popup2, div.popup8{
	display: none;
	z-index: 15;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255,255,255,0.8);
	div.popup_content{
		background-color: $white;
		padding: 40px 0px;
		border: 1px solid rgba(32,40,50,0.5);
		position: relative;
	}
	header.popup_header{
		div.default_font{
			padding-bottom: 30px;
			margin-bottom: 30px;
			position: relative;
			&:after{
				content: '';
				display: block;
				height: 3px;
				width: 100%;
				position: absolute;
				top: 100%;
				left: 0;
				background-image: url('./../img/lines3.png');
				background-position: left top;
			}
			h2{
				line-height: 50px;
			}
		}
	}
	div.movie_popup{
		width: 89%;
		margin: 0 auto;
		height: 0;
		padding-bottom: 50%;
		position: relative;
		overflow: hidden;
		> iframe{
			@include fill;
		}
	}
}

div.popup3{
	//display: none;
	z-index: 15;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(255,255,255,0.8);
	img{
		margin-bottom: 30px;
		max-width: 100%;
	}
	a.more1{
		cursor: pointer;
	}
	div.popup_content{
		background-color: $white;
		padding: 40px 0px;
		border: 1px solid rgba(32,40,50,0.5);
		position: relative;
	}
	header.popup_header{
		div.default_font{
			padding-bottom: 30px;
			margin-bottom: 30px;
			display: inline-block;
			position: relative;
			&:after{
				content: '';
				display: block;
				height: 3px;
				width: 100%;
				position: absolute;
				top: 100%;
				left: 0;
				background-image: url('./../img/lines3.png');
				background-position: left top;
			}
			h2{
				line-height: 50px;
			}
		}
	}
	div.sign_in{
		padding: 0 40px;
	}
}

section.subpage_top{
	div.container-fluid{
		> div.row{
			> div{
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	div.page_title{
		height: 325px;
		background-color: $yellow;
		position: relative;
		div.default_font{
			width: 60%;
			margin: 0 auto;
			h1{
				font-size: 60px;
				line-height: 68px;
			}
		}
	}
	div.top_image{
		height: 325px;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		div.light{
			width: 323px;
			height: 515px;
			position: absolute;
			top: -30%;
			right: 15%;
			background-repeat: no-repeat;
			background-position: center;
			background-size:contain;
			background-image: url('./../img/light_img.png');
		}
	}
}

section.news_details{
	padding: 5% 0 0 0;
	margin-bottom: 30px;
	header.news_header{
		> div.default_font{
			/*h1,h2,h3,h4,h5,h6,p,ol,ul,a{
				padding-bottom: 15px;
				&:last-child{
					padding-bottom: 0;
				}
			}*/
			h2{
				font-size: 30px;
    			line-height: 36px;
				padding-bottom: 15px;
			}
			h3{
				font-size: 20px;
    			line-height: 36px;
			}
			position: relative;
			padding-bottom: 15px;
			margin-bottom: 20px;
			&:after{
				position: absolute;
				content: '';
				display: block;
				width: 200%;
				right: 0;
				top: 100%;
				background-repeat: repeat-x;
				background-position: bottom right;
				background-image: url(./../img/section_header_border.png);
				height: 3px;
			}
		}
		div.date{
			margin-bottom: 30px;
		}
	}
	div.text_desc{
		div.default_font{
			text-align: justify;
			line-height: 30px;
			h1,h2,h3,h4,h5,h6,p,ul,ol{
				//padding-bottom: 34px;
				padding-bottom: 17px;
				&:last-child{
					padding-bottom: 0;
				}
			}
			p{
				s{
					a{
						display: inline-block;
						padding: 15px 30px;
						font-size: 15px;
						font-weight: 700;
						background-color: $yellow;
						border-radius: 50px;
						color: $black;
						margin-bottom: 17px;
						text-decoration: none;
					}
				}
			}
		}
	}
	div.news_image{
		height: 390px;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		margin-bottom: 30px;
		border-radius: 10px;
	}
	div.map_holder{
		width: 100%;
		position: relative;
		padding-bottom: 100%;
		height: 0;
		> iframe{
			@include fill;
		}
	}
	div.school_info{
		padding-top: 30px;
		div.text_desc{
			div.default_font{
				h1,h2,h3,h4,h5,h6,p{
					font-size: 20px;
    				line-height: 36px;
				}
			}
		}
	}
}

section.galery{
	header.galery_header{
		margin-bottom: 25px;
		div.default_font{
			position: relative;
			h1,h2,h3,h4,h5,h6,p{
				background-color: $white;
				display: inline-block;
				padding-right: 15px;
			}
			&:after{
				content: '';
				display: block;
				height: 3px;
				background-position: right top;
				background-repeat: repeat-x;
				background-image: url(./../img/section_header_border.png);
				position: absolute;
				bottom: 5px;
				left: 0;
				width: 100%;
				z-index: -1;
			}
		}
	}
	div.photo_list{
		ul{
			li{
				display: inline-block;
				width: 19%;
				height: 0;
				padding-bottom: 19%;
				margin-right: 1.2%;
				margin-bottom: 1.2%;
				overflow: hidden;
				position: relative;
				border-radius: 10px;
				&:last-child{
					margin-right: 0;
				}
				&:hover{
					div.image{
						transform: scale(1.1);
					}
				}
				> div.image{
					@include fill;
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center;
					transition: 0.3s all ease;
				}
			}
		}
	}
}

section.about_movie{
	padding-bottom: 3%;
	div.container{
		
	}
	@media (min-width: 768px) {
	  .container {
		  width: 750px;
	  }
	}
	@media (min-width: 992px) {
	  .container {
		  width: 970px;
	  }
	}
	@media (min-width: 1200px) {
	  .container {
		  width: 1200px;
	  }
	}
	div.desc{
		text-align: right;
		position: relative;
		header.section_header{
			margin: 50px 0 30px 0;
			div.default_font{
				h1,h2,h3,h4,h5,h6,p{
					font-weight: 700;
					strong{
						color: $yellow;
					}
				}
				&:before{
					display: none;
				}
			}
		}
		div.text_desc{
			margin-bottom: 30px;
		}
		div.default_font{
			text-align: left;
		}
	}
	div.image{
		min-height: 440px;
		background-position: left center;
		background-repeat: no-repeat;
		background-size: contain;
		width: 100%;
	}
	a.movie_button{
		padding: 20px 90px 20px 30px;
		border-radius: 50px;
		background-position: right 30px center;
		background-repeat: no-repeat;
		text-transform: uppercase;
		cursor: pointer;
		display: inline-block;
		background-color: $yellow;
		background-image: url('./../img/play_icon.png');
		color: $black;
		font-size: 12px;
		font-weight: 700;
	}
}

section.map{
	padding: 5% 0 5% 0;
	header.section_header{
		margin-bottom: 40px;
		div.default_font{
			display: inline-block;
			padding-right: 15px;
			background-color: $white;
			&:before{
				content: '';
				display: block;
				left: 100%;
				top: 90%;
				height: 3px;
				background-position: right top;
				background-repeat: repeat-x;
				background-image: url(./../img/section_header_border.png);
				position: absolute;
				width: 500%;
			}
		}
	}
	div.text_desc{
		div.default_font{
			min-height: 220px;
			h1,h2,h3,h4,h5,h6,p{
				font-size: 20px;
				line-height: 36px;
			}
		}
	}
	div.image{
		position: relative;
		img{
			position: absolute;
			top: -114px;
			left: -75px;
		}
	}
	div.map_selects{
		margin: 25px 0;
		form{
			> ul{
				text-align: center;
				> li{
					display: inline-block;
					vertical-align: top;
					margin-right: 12px;
					&:last-child{
						margin-right: 12px;
					}
					.sbHolder{
						width: 208px;
						height: 50px;
						border-radius: 0;
						border: none;
						background-color: $yellow;
						text-align: left;
						.sbSelector{
							&:link{
								font-size: 10px;
								font-weight: 700;
								padding: 18px 0 17px 15px;
							}
						}
					}
					.sbToggle{background-image: url('/img/select_arr3.png');}
					.sbOptions{font-size: 10px; text-align: left; border-radius: 0;}
					.sbOptions a{padding: 10px 15px; width: 100%;}
					div.holder2{
						width: 185px;
						input.input{
							width: 100%;
							height: 50px;
							padding-left: 15px;
							background-color: #f5f5f5;
							color: $black;
							font-size: 10px;
							font-weight: 700;
							border: none;
							outline: none;
						}
					}
					input.submit2{
						width: 63px;
						height: 50px;
						background-color: $black;
						border-radius: 0;
						border: none;
						outline: none;
						background-position: center;
						background-repeat: no-repeat;
						background-image: url('./../img/search_icon1.png');
					}
				}
			}
		}
	}
	div.map_holder{
		width: 100%;
		position: relative;
		height: 512px;
		> iframe{
			@include fill;
		}
		> div.map_info{
			z-index: 5;
			position: absolute;
			top: 0;
			right: 0;
			width: 40%;
			background-color: $yellow;
			text-align: center;
			> div{
				padding:30px 15px 15px 15px;
				&.text_desc{
					> div.default_font{
						min-height: auto;
						h1,h2,h3,h4,h5,h6,p,ul,ol,a{
							padding-bottom: 15px;
							&:last-child{
								padding-bottom: 0;
							}
						}
						h5{
							line-height: 30px;
						}
					}
					a.more1{
						margin-top: 10px;
						font-size: 12px;
						padding: 17px 20px;
					}
				}
			}
		}
	}
}

section.map{
	div.orange_map{
		height: 583px;
		width: 100%;
		position: relative;
		iframe{
			@include fill;
		}
		div.map_blocker{
			@include fill;
			z-index: 2;
		}
	}
}

div.map_info_inside{
	padding: 8px 8px 0 0;
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	//width: 50%;
	> div{
		display: inline-block;
		vertical-align: top;
		//width: 50%;
	}
	div.information{
		width: 270px;
		position: relative;
		padding:30px 15px 30px 15px;
		background-color: #ffd12a;
		color: #000000;
		font-family: $montserrat;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		h2{
			font-size: 20px;
			font-weight: 700;
			padding-bottom: 10px;
		}
		h3{
			font-size: 15px;
			font-weight: 700;
		}
		header.information_header{
			padding-bottom: 20px;
		}
	}
	div.calendar{
		margin-left: 8px;
	}
}

.close_icon {
	top: 10px;
	right: 10px;
	color: #000;
	position: absolute;
	margin-top: 0;
	margin-left: 0;
	width: 21px;
	height: 21px;
	cursor: pointer;
	z-index: 10;
}
.close_icon:before {
	content: '';
	position: absolute;
	top: 7px;
	left: 0;
	width: 21px;
	height: 1px;
	background-color: currentColor;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.close_icon:after {
	content: '';
	position: absolute;
	top: 7px;
	left: 0;
	width: 21px;
	height: 1px;
	background-color: currentColor;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

section.knowledge{
	padding: 5% 0;
	header.section_header{
		margin-bottom: 40px;
		div.default_font{
			display: inline-block;
			padding-right: 15px;
			background-color: $white;
			&:before{
				content: '';
				display: block;
				left: 100%;
				top: 90%;
				height: 3px;
				background-position: right top;
				background-repeat: repeat-x;
				background-image: url(./../img/section_header_border.png);
				position: absolute;
				width: 500%;
			}
		}
	}
	div.scenario_list{
		> ul{
			> li{
				width: 100%;
				padding: 30px 0;
				border-bottom: 2px solid #eee;
				&:last-child{
					border-bottom: none;
				}
				div.files{
					padding-top: 15px;
					> ul{
						text-align: right;
						> li{
							display: inline-block;
							vertical-align: top;
							margin-right: 15px;
							&:last-child{
								margin-right: 0;
							}
							&.doc_file{
								> a{
									background-image: url('/img/doc_icon.png');
								}
							}
							&.pdf_file{
								> a{
									background-image: url('/img/pdf_icon.png');
								}
							}
							> a{
								font-size: 15px;
								color: $yellow;
								display: inline-block;
								padding: 5px 0 5px 40px;
								background-repeat: no-repeat;
								background-position: left center;
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}
}

section.educators{
	padding: 5% 0 0 0;
	header.educators_header{
		div.default_font{
			padding-bottom: 30px;
			margin-bottom: 30px;
			position: relative;
			&:after{
				content: '';
				display: block;
				height: 3px;
				width: 100%;
				position: absolute;
				top: 100%;
				left: 0;
				background-image: url('./../img/lines3.png');
				background-position: left top;
			}
			h2{
				line-height: 50px;
			}
			h3{
				line-height: 46px;
			}
		}
	}
	div.holder{
		
label.label_small{
	font-size: 18px;
    padding-bottom: 0;
	margin-bottom: 15px;
	display: block;
}
		margin-bottom: 24px;
		&:last-child{
			//padding-top: 30px;
			margin-bottom: 0;
		}
		p, label{
			font-size: 26px;
			font-weight: 300;
			color: #000000;
			padding-bottom: 24px;
		}
		label{
			display: block;
		}
		input.input{
			width: 100%;
			height: 77px;
			border: 1px solid #acacac;
			//border-radius: 50px;
			border-radius: 15px;
			padding-left: 47px;
			outline: none;
			font-family: $montserrat;
			color: #000000;
			font-size: 26px;
			font-weight: 300;
			outline: none;
		}
		input.submit{
			width: 100%;
			height: 66px;
			background-color: $yellow;
			font-family: $montserrat;
			font-size: 20px;
			font-weight: 700;
			color: $black;
			border-radius: 50px;
			border: 1px solid $yellow;
			outline: none;
		}
		a.cancel{
			text-align: center;
			display: block;
			width: 100%;
			padding: 20px 0;
			background-color: $black;
			font-family: $montserrat;
			font-size: 20px;
			font-weight: 700;
			color: $white;
			border-radius: 50px;
			border: 1px solid $black;
		}
		.sbHolder{
			margin-bottom: 15px;
		}
		&.accept_rules{
			padding-top: 24px;
			padding-bottom: 5%;
			div.check_box{
				> div{
					vertical-align: top;
					&.desc{
						text-align: justify;
					}
				}
			}
		}
	}
	div.form_logotypes{
		padding-top: 5%;
		img{
			max-height: 55px;
			width: auto;
		}
	}
}

div.check_box{
	margin-bottom: 24px;
	/*margin-bottom: 15px;
	&:last-child{
		margin-bottom: 24px;
	}*/
	> div{
		display: inline-block;
		vertical-align: middle;
		.box {
			cursor: pointer;
		  border:1px solid #acacac;
		  width:40px;
		  height:0;
		  padding-bottom: 40px;
		  position:relative;
			border-radius: 5px;
			> div{
				@include fill;
				display: none;
				background-position: center;
				background-repeat: no-repeat;

				&.on{
					&.yes{
						display: block;
						width: 80%;
						left: 10%;
						top: 10%;
						height: 80%;
						position: absolute;
						background-color: $yellow;
						border-radius: 5px;
					}
				}
			}
		}
		&.check{
			width: 7%;
		}
		&.desc{
			width: 93%;
			p, label{
				font-size: 18px;
				padding-bottom: 0;
			}
			label{
				display: block;
			}
		}
	}
}

div.underslider_logotypes{
	padding:2% 0 1% 0;
	div.col-md-4{
		&:nth-child(2){
			text-align: center;
		}
		&:nth-child(3){
			text-align: center;
		}
		&:nth-child(4){
			text-align: right;
		}
	}
	/*img{
		max-width: 100%;
		width: auto;
	}*/
}

div.popup_content{
	header.popup_header{
		padding-bottom: 30px;
		margin-bottom: 30px;
		position: relative;
		&:after{
			content: '';
			display: block;
			height: 3px;
			width: 100%;
			position: absolute;
			top: 100%;
			left: 0;
			background-image: url(./../img/lines3.png);
			background-position: left top;
		}
	}
}

div.text_desc{
	.h2{
		font-size: 40px;
		line-height: 50px;
	}
	.h3{
		font-size: 30px;
		line-height: 36px;
	}

	.h1{
		font-size: 60px;
		line-height: 70p;
	}
	.h4{
		font-size: 24px;
		line-height: 30px;
	}
	.h5{
		font-size: 20px;
		line-height: 36px;
	}
	.h6{
		font-size: 18px;
		line-height: 24px;
	}
}

.h2{
	font-size: 40px;
	line-height: 50px;
	margin-top: 0;
	margin-bottom: 0;
}
.h3{
	font-size: 30px;
	line-height: 36px;
	margin-top: 0;
	margin-bottom: 0;
}

.h1{
	font-size: 60px;
	line-height: 70p;
	margin-top: 0;
	margin-bottom: 0;
}
.h4{
	font-size: 24px;
	line-height: 30px;
	margin-top: 0;
	margin-bottom: 0;
}
.h5{
	font-size: 20px;
	line-height: 36px;
	margin-top: 0;
	margin-bottom: 0;
}
.h6{
	font-size: 18px;
	line-height: 24px;
	margin-top: 0;
	margin-bottom: 0;
}

.all_schools{
	div.default_font{
		h1,h2,h3,h4,h5,h6{
			padding-bottom: 17px;
			&:last-child{
				padding-bottom: 0;
			}
		}
		ul,ol,li{
			padding-bottom: 15px;
			&:last-child{
				padding-bottom: 0;
			}
		}
	}
}

.only_text{
	padding:0 0 5% 0;
	div.text_desc{
		div.default_font{
			text-align: justify;
    		line-height: 30px;
			font-size: 20px;
    		line-height: 36px;
		}
	}
}

section.short_boxes{
	header.section_header{
		padding-bottom: 8%;
	}
	div.short_box{
		margin-bottom: 50px;
		header{
			margin-bottom: 15px;
			> div.text_desc{
				margin-bottom: 10px;
			}
			div.link{
				div.default_font{
					a{
						color: $yellow;
						font-size: 20px;
    					line-height: 36px;
					}
				}
			}
		}
		div.desc{
			div.default_font{
				line-height: 30px;
				text-align: justify;
				font-size: 20px;
    			line-height: 36px;
				a{
					color: $yellow;
				}
			}
		}
	}
}

div.popup1{
	padding-left: 30px;
	overflow-y: scroll;
}